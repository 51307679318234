import React from 'react';
import './Home.css';
import headshot from './images/KT_Meta_Headshot_Copy.png';

function Home() {
    return (
      <div>
        <div className='text-center'>
            <h2>Hi, I'm Kostas Tingos...</h2>
            <img src={headshot} alt='Kostas Tingos Headshot' id='headshot'></img>
        </div>
        <div id='home-info-row'>
          <div id='about-me'>
              <h2 className='text-center'>About</h2>
              <ul>
                <li>Currently living in NYC</li>
                <li>Studied Applied Math in Economics 
                    and Computer Science at Harvard College</li>
                <li>Originally from Boston, MA</li>
                <li>Languages I know are English, Greek, and Spanish</li>
              </ul>
          </div>
          <div id='experience'>
              <h2 className='text-center'>Experience</h2>
              <ul>
                <li>Software Engineer @ Bloomberg</li>
                <li>Researcher @ Rajpurkar Lab, Harvard Medical School</li>
                <li>Software Engineer Intern @ Meta</li>
                <li>Teaching Fellow in Applied Math @ Harvard</li>
                <li>Software Dev Engineer Intern @ Amazon</li>
                <li>Investment Science and Technology Intern @ Wellington Management</li>
                <li>Software Intern @ Dell EMC</li>
              </ul>
          </div>
        </div>
      </div>
    );
  }

export default Home;