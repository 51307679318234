import React from 'react';
import './Projects.css';
import reading_tracker_pic from './images/readingtrackerpic.png';
import tictactoe_pic from './images/tictactoepic.png';
import flashcards_pic from './images/flashcards.png';
import pharmacology_pic from './images/am115_pharmacology.png';
import shuttle_pic from './images/am115_shuttle.png';
import battleship_pic from './images/am115_battleship.png';
import cs197_pic from './images/cs197_chexmera.png';
import midl2023_pic from './images/midl2023.png';

function Projects() {
    return (
      <div>
        <h2>Projects</h2>
        <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading-midl2023">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-midl2023" aria-expanded="false" aria-controls="collapse-midl2023">
              Improving Detection of Low Prevalence Chest Pathologies in X-Rays (MIDL 2023)
              </button>
            </h2>
            <div id="collapse-midl2023" class="accordion-collapse collapse" aria-labelledby="heading-midl2023">
              <div class="accordion-body">
              Recent advances in zero-shot learning have enabled the use of paired image-text data
              to replace structured labels, replacing the need for expert annotated datasets. Models such
              as CLIP-based CheXzero utilize these advancements in the domain of chest X-ray interpretation. 
              We hypothesize that domain pre-trained models such as CXR-BERT, BlueBERT,
              and ClinicalBERT offer the potential to improve the performance of CLIP-like models with
              specific domain knowledge by replacing BERT weights at the cost of breaking the original
              model’s alignment. We evaluate the performance of zero-shot classification models with
              domain-specific pre-training for detecting low-prevalence pathologies. 
              Even though replacing the weights of the original CLIP-BERT degrades model performance on commonly
              found pathologies, we show that pre-trained text towers perform exceptionally better on
              low-prevalence diseases.<br></br>
                <b>Links:</b> <a href="https://arxiv.org/abs/2306.08000" target="_blank" rel="noopener noreferrer">Paper (arXiv)</a><br></br>
                <div className='project-image-div'>
                  <img src={midl2023_pic} alt="MIDL 2023 Submission" className='project-image'></img>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-cs197">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-cs197" aria-expanded="false" aria-controls="collapse-cs197">
              Zero-Shot Chest X-Ray Pathology Classification
              </button>
            </h2>
            <div id="collapse-cs197" class="accordion-collapse collapse" aria-labelledby="heading-cs197">
              <div class="accordion-body">
              In this work, we explore the combination of generalized and specialized pre-training 
              to improve zero-shot pathology classification in chext x-rays. This combination of pre-training
              methods, which we call CheXmera, replaces the image or text tower of a general baseline 
              with a locked or unlocked specialized pre-trained model. We find that strong image pre-training 
              alone leads to the biggest increase in performance over the baseline, but pre-trained text towers 
              remain valuable as they demonstrate extremely strong performance with pathologies that are 
              rarely mentioned in the alignment training dataset. We also discover that unlocking these 
              specialized towers during the tuning does not seem to degrade the rich pre-trained
              embedding space and is instead beneficial across both the image and text modalities. <br></br>
                <b>Links:</b> <a href="https://drive.google.com/file/d/1zYu7xm42dBGJTtdWuXRXjinWfGbGHesC/view?usp=sharing" target="_blank" rel="noopener noreferrer">Paper</a>, <a href='https://github.com/greenFantasy/cs197-final-project' target="_blank" rel="noopener noreferrer">GitHub</a> <br></br>
                <div className='project-image-div'>
                  <img src={cs197_pic} alt="Zero-Shot Chest X-Ray Pathology Classification Paper" className='project-image'></img>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-battleship">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-battleship" aria-expanded="false" aria-controls="collapse-battleship">
                A Stochastic Model of Battleship
              </button>
            </h2>
            <div id="collapse-battleship" class="accordion-collapse collapse" aria-labelledby="heading-battleship">
              <div class="accordion-body">
                Battleship is a popular 2-player game with ample opportunity for strategic approaches to
                ship guessing and placement. To understand the impact of various strategies, we created a
                stochastic model to simulate the game. <br></br>
                <b>Links:</b> <a href="https://drive.google.com/file/d/143EBmAEXqUjNU2egEqLAKp4V9VRbtdEq/view?usp=sharing" target="_blank" rel="noopener noreferrer">Report</a>, <a href='https://colab.research.google.com/drive/1yaTKhHJ5jTRLhgoZ0R7EwM4zKDj0md1-?usp=sharing' target="_blank" rel="noopener noreferrer">Colab</a> <br></br>
                <div className='project-image-div'>
                  <img src={battleship_pic} alt="Battleship Project" className='project-image'></img>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-shuttle">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-shuttle" aria-expanded="false" aria-controls="collapse-shuttle">
                Optimizing Harvard’s Shuttle Bus System
              </button>
            </h2>
            <div id="collapse-shuttle" class="accordion-collapse collapse" aria-labelledby="heading-shuttle">
              <div class="accordion-body">
                The Harvard shuttle system is an underutilized campus resource, particularly due to the
                long wait times. To improve the shuttle experience for students, we developed a stochastic
                model to optimize the Harvard shuttle system and minimize wait times. <br></br>
                <b>Links:</b> <a href="https://drive.google.com/file/d/1ZwgL6E4jIyRCxZSey_oabohGPUnl0T-9/view?usp=share_link" target="_blank" rel="noopener noreferrer">Report</a>, <a href='https://colab.research.google.com/drive/1WSyrc4DBHHxLuGHIx6HxbQftPyDKmGxG?usp=sharing' target="_blank" rel="noopener noreferrer">Colab</a> <br></br>
                <div className='project-image-div'>
                  <img src={shuttle_pic} alt="Harvard Shuttle System Project" className='project-image'></img>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-pharmacology">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-pharmacology" aria-expanded="false" aria-controls="collapse-pharmacology">
                Pharmacology Model: Acetaminophen
              </button>
            </h2>
            <div id="collapse-pharmacology" class="accordion-collapse collapse" aria-labelledby="heading-pharmacology">
              <div class="accordion-body">
                Acetaminophen (APAP) is one of the most commonly taken medications in the world and
                accounts for nearly half of acute liver failure cases in the US. Thus, we sought to model the
                metabolism of APAP and the formation of its toxic product. Specifically, we created a system
                of differential equations to model the three most important pathways in APAP metabolism. <br></br>
                <b>Links:</b> <a href="https://drive.google.com/file/d/1b_2fb0WGYVjdHPZJHO_Yp4rEWQRWShM3/view?usp=share_link" target="_blank" rel="noopener noreferrer">Report</a>, <a href='https://colab.research.google.com/drive/106EhMnSzDSGvfQDleoVptsOfXYFqaP8S?usp=sharing' target="_blank" rel="noopener noreferrer">Colab</a> <br></br>
                <div className='project-image-div'>
                  <img src={pharmacology_pic} alt="Pharmacology Model" className='project-image'></img>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-readingtracker">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-readingtracker" aria-expanded="false" aria-controls="collapse-readingtracker">
                Reading Tracker
              </button>
            </h2>
            <div id="collapse-readingtracker" class="accordion-collapse collapse" aria-labelledby="heading-readingtracker">
              <div class="accordion-body">
                This Flask web application allows users to create an account and keep track of their reading. 
                Users can log individual books and how many pages they've read on a given day.
                They can also see a graphic for how much they've been reading over the past week and book suggestions 
                based on what others have logged. <br></br>
                <b>Links:</b> <a href="https://github.com/ktingos/readingtracker" target="_blank" rel="noopener noreferrer">GitHub</a> <br></br>
                <div className='project-image-div'>
                  <img src={reading_tracker_pic} alt="Reading Tracker" className='project-image'></img>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-tictactoe">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-tictactoe" aria-expanded="false" aria-controls="collapse-tictactoe">
                Tic-Tac-Toe
              </button>
            </h2>
            <div id="collapse-tictactoe" class="accordion-collapse collapse" aria-labelledby="heading-tictactoe">
              <div class="accordion-body">
                This Flask web application lets you play Tic-Tac-Toe with a friend. You can restart the game at
                any time, undo a move, and see a full history of all the moves after the game is complete. <br></br>
                <b>Links:</b> <a href="https://github.com/ktingos/tictactoe" target="_blank" rel="noopener noreferrer">GitHub</a> <br></br>
                <div className='project-image-div'>
                  <img src={tictactoe_pic} alt="Tic-Tac-Toe" className='project-image'></img>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="heading-flashcards">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-flashcards" aria-expanded="false" aria-controls="collapse-flashcards">
                Flashcards
              </button>
            </h2>
            <div id="collapse-flashcards" class="accordion-collapse collapse" aria-labelledby="heading-flashcards">
              <div class="accordion-body">
                This is a React application where users can make and study flashcards. You create and remove
                flashcards in the editor, and then you can study them in the viewer. <br></br>
                <b>Links:</b> <a href="https://github.com/ktingos/flashcards" target="_blank" rel="noopener noreferrer">GitHub</a>
                <div className='project-image-div'>
                  <img src={flashcards_pic} alt="Flashcards" className='project-image'></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Projects;