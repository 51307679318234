import React from 'react';
import './App.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';

import Home from './Home.js';
import Projects from './Projects.js';
// import Contact from './Contact.js';

function Navigation() {
  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark header">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto">
                    <li className="nav-item"><div className='nav-link'><Link to="/">Home</Link></div></li>
                    <li className="nav-item"><div className='nav-link'><Link to="/projects">Projects</Link></div></li>
                    {/* <li className="nav-item"><div className='nav-link'><Link to="/contact">Contact</Link></div></li> */}
                </ul>
            </div>
        </nav>

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <div id='page'>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            {/* <Route path="/contact">
              <Contact />
            </Route> */}
          </Switch>
        </div> 
      </div>
    </Router>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div id='name' className='header'>Kostas Tingos</div>
        <Navigation />
      </header>
      <footer className='small text-center text-muted' id='footer'>
        <div id='icons'>
            <a className="fa fa-envelope" href='mailto:kostastingos@college.harvard.edu' target="_blank" rel="noopener noreferrer"> </a> &nbsp;
            <a className="fa fa-linkedin" href="https://www.linkedin.com/in/kostas-tingos-2493b8178/" target="_blank" rel="noopener noreferrer"> </a> &nbsp;
            <a className="fa fa-github" href="https://github.com/ktingos" target="_blank" rel="noopener noreferrer"> </a> &nbsp;
            <a className="fa fa-instagram" href="https://www.instagram.com/kostastingos/?hl=en" target="_blank" rel="noopener noreferrer"> </a> &nbsp;
        </div>
        Made by Kostas Tingos, Last Updated August 2023
      </footer>
    </div>
  );
}

export default App;
